/*Menu de navegacao categorias*/
$("#toggle").click(function (e) {
         $(this).closest('#toggle').toggleClass("on");
});
$(document).on('click', function (e) {    
    if (!$(e.target).closest('.on').length) $('#toggle').removeClass("on");
});


$('#yes').click(function(){
    removeModalHomeClick();
    setCookie("modalHomeClick",1,7);
});


/*Thumb para o Video*/
$('#player-img-icon').click(function(){
    $(".player-img").addClass('hide');
    $("#frameVideo").removeClass('hide');
    $("#frameVideo").show("slow");
    $(".embed-responsive-item").attr('src', $(".embed-responsive-item").attr('src') +'?autoplay=1');
});


/*Apagar as Luzes Video */
var per = 0;
$(document).ready(function(){
  $("#apaga").css("height", $(document).height()).hide();
  $(document).click(function(e) {
	if(!$(e.target).hasClass('light') && per == 1) {
	  $("#apaga").toggle();
	  per = 0;
	}
  });
  $(".light").click(function(){
    $("#apaga").toggle();
	per += 1;
    if (per == 2) {
	  per = 0;
	}
  });
});


/*Valida Forms*/



$("#code").submit(function(){
    var code = $('#codeInput').val();
       if(code == ''){
       sweetAlert("", "Insira o código", "error");
        return false;
        }
    
      if(code.length < 4 ){
       sweetAlert("", "Prencha o Código Corretamente", "error");
        return false;
        }
    
    if(code.length > 4 ){
       sweetAlert("", "Prencha o Código Corretamente", "error");
        return false;
        }

        else{
        return true
        }
});   


function submitBusca() {
    var word = $("#search").val();
    if(!word){
        return false;
    }
    $("#mega-search").prop("action","/busca/"+word);
    return true;
}

$("#banner").click(function(){
    var go_to_url = "http://www.sexrevolution.com.br/";
    window.open(go_to_url, '_blank');
});



$('.owl-carousel').owlCarousel({
    loop: true,
    margin: 0,
    responsiveClass: true,
    items: 3,
    autoplay: true,
    autoplayTimeout: 5000,
    nav: false,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        600: {
            items: 2,
            nav: false
        },
        1000: {
            items: 3,
            nav: true,
            loop: false,
            margin: 20
        },
        1200: {
            items: 3,
            nav: true,
            loop: false,
            margin: 20
        }

    }
});

